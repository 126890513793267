<template>
  <div style="width: 100%">
    <div style="padding: 15px;display: block;">
      <el-page-header @back="goBack" content="客户详情页面">
      </el-page-header>
    </div>
    <div class="mb-detail" v-loading="loading">
      <div class="mb-member">
        <div style="display: flex;flex-direction: row;justify-content: space-between;font-size: 15px">
          <span>会员资料</span>
          <span style="color: #2b94ff;cursor: pointer" @click="toEditMember"><i class="el-icon-edit-outline"
                                                                                style="margin-right: 3px"></i>编辑</span>
        </div>
        <div class="mbinfo-top">
          <img v-if="!member.headPortrait&&member.sex==0" src="../../assets/images/nan.png" class="img">
          <img v-if="!member.headPortrait&&member.sex==1" src="../../assets/images/nan.png" class="img">
          <img v-if="!member.headPortrait&&member.sex==2" src="../../assets/images/nv.png" class="img">
          <img v-if="member.headPortrait" :src="member.headPortrait" class="img">
          <div class="mb-item">
            <span style="font-size: 18px;margin-bottom: 8px">{{member.memberName}}</span>
            <span>{{member.jmPhone}}</span>
            <span>生日：{{member.zcDate}}</span>
          </div>
          <span class="mb-sex">
          <span v-if="member.sex==0">未知</span>
          <span v-if="member.sex==1">男</span>
          <span v-if="member.sex==2">女</span>
        </span>
        </div>
        <div class="mbinfo-zhong">
          <span class="mz-sp" style="margin-top: 12px">归属门店： {{member.deptName}}</span>
          <span class="mz-sp">注册时间: {{member.createTime}}</span>
          <span class="mz-sp">是否已添加门店微信:
            <el-switch
              @change="updateAddWx"
              style="margin-left: 5px"
              v-model="member.addWx"
              :active-value="2"
              :inactive-value="1"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
          </span>
          <span class="mz-sp">进店来源:
        <span v-if="member.mbSource==0">自来</span>
        <span v-if="member.mbSource==1">抖音</span>
        <span v-if="member.mbSource==2">美团</span>
        <span v-if="member.mbSource==3">小红书</span>
        <span v-if="member.mbSource==4">支付宝</span>
        <span v-if="member.mbSource==5">私域商城</span>
        <span v-if="member.mbSource==8">其他平台</span>
      </span>
          <span class="mz-sp">推荐人:
             <span v-if="tjmbItem&&tjmbItem.id">{{tjmbItem.memberName}}</span>
            <span v-else style="color: #2b94ff;cursor: pointer" @click="toEditTj">编辑</span>
          </span>
          <span class="mz-sp">第一负责人: {{member.oneName}}</span>
          <span class="mz-sp">第二负责人: {{member.twoName}}</span>
          <span class="mz-sp">第三负责人: {{member.threeName}}</span>
          <span class="mz-sp">微信推送: <span style="margin-left: 10px;color: #2b94ff;cursor: pointer">绑定</span></span>
          <span class="mz-sp">密码管理: <span style="margin-left: 10px;color: #2b94ff;cursor: pointer"
                                          @click="toPwd">设置</span></span>
          <div>
            <span class="mz-sp">备注</span>
            <el-input
              type="textarea"
              :rows="6"
              v-model="member.remarks"
              @blur="updateMemberRemarks(member.remarks)"
            >
            </el-input>
            <span style="display: block;text-align: right;margin-top: 5px">
              备注输入内容后自动保存
           </span>
          </div>
        </div>
      </div>
      <div class="mb-record">
        <div class="mb-tongji">
          <div class="tj-items">
            <div class="tj-item">
              <div class="tj-nnn" style="background-color: #FFF5F5">
                <p>门店累计消费金额</p>
                <div class="tj-value">
                  <span>¥ {{swRes.cardAmount}}<span style="font-size: 11px"> (卡)</span> / </span>
                  <span style="color: #00ACEF">¥ {{swRes.giveAmount}}<span style="font-size: 11px"> (赠)</span></span>
                </div>
              </div>
            </div>
            <div class="tj-item">
              <div class="tj-nnn" style="background-color: #F9F5FF">
                <p>到店消费次数</p>
                <p class="tj-value"> {{swRes.mdOrderNum}}</p>
              </div>
            </div>
            <div class="tj-item">
              <div class="tj-nnn" style="background-color: #ECFAFD">
                <p>商城累计消费金额</p>
                <p class="tj-value"> ¥ 0</p>
              </div>
            </div>
            <div class="tj-item">
              <div class="tj-nnn" style="background-color: #F1F6FF">
                <p>欠款</p>
                <p class="tj-value"> ¥ {{swRes.qkAmount}}</p>
              </div>
            </div>
          </div>
          <div class="tj-items">
            <div class="tj-item">
              <div class="tj-nnn" style="background-color: #F1F6FF">
                <p>可用积分</p>
                <p class="tj-value"> 0</p>
              </div>
            </div>
            <div class="tj-item">
              <div class="tj-nnn" style="background-color: #F6F2EC">
                <p>最近消费时间</p>
                <p class="tj-value"> {{xfDate==null?'暂无':xfDate}}</p>
              </div>
            </div>
            <div class="tj-item">
              <div class="tj-nnn" style="background-color: #F1F6FF">
                <p>消费周期</p>
                <p class="tj-value"> {{swRes.xfCycle}}</p>
              </div>
            </div>
            <div class="tj-item">
              <div class="tj-nnn" style="background-color: #FFF5F5">
                <p>充值周期</p>
                <p class="tj-value"> {{swRes.czCycle}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="mbr-bottom">
          <div class="mb-title">
            <el-tabs v-model="type" type="card">
              <el-tab-pane name="0">
                <span slot="label"><i class="el-icon-s-order"></i>会员卡列表</span>
              </el-tab-pane>
              <el-tab-pane name="1">
                <span slot="label"><i class="el-icon-s-order"></i>服务商品</span>
              </el-tab-pane>
              <el-tab-pane name="2">
                <span slot="label"><i class="el-icon-s-order"></i>门店套餐</span>
              </el-tab-pane>
              <el-tab-pane name="3">
                <span slot="label"><i class="el-icon-s-order"></i>年/季/月卡</span>
              </el-tab-pane>
              <el-tab-pane name="4">
                <span slot="label"><i class="el-icon-s-order"></i>消费记录</span>
              </el-tab-pane>
              <el-tab-pane name="5">
                <span slot="label"><i class="el-icon-s-order"></i>商城到店</span>
              </el-tab-pane>
              <el-tab-pane name="6">
                <span slot="label"><i class="el-icon-s-order"></i>直接推荐客户</span>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div v-if="type==0" class="mb-tab">
            <el-table
              :data="list"
              :header-cell-style="{fontSize:'13px'}"
              :cell-style="{fontSize:'12px'}"
              style="width: 100%;">
              <el-table-column prop="cardName" :key="Math.random()" label="会员卡名称" align="center"/>
              <el-table-column prop="orderNo" label="会员卡类型" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.cardType==0">储值卡</span>
                  <span v-if="scope.row.cardType==1">年卡</span>
                  <span v-if="scope.row.cardType==2">原价卡</span>
                </template>
              </el-table-column>
              <el-table-column prop="balance" :key="Math.random()" label="卡余额" align="center">
              </el-table-column>
              <el-table-column prop="giveBalance" :key="Math.random()" label="赠送金" align="center">
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="cardPage"
                :pager-count="7"
                layout="total, prev, pager, next"
                :total="pagination.count"
                :page-count="pagination.pageCount"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
          <div v-if="type==1" class="mb-tab">
            <div>
              <el-table
                :data="cpList"
                :header-cell-style="{fontSize:'13px'}"
                :cell-style="{fontSize:'12px'}"
                style="width: 100%;">
                <el-table-column prop="skuName" label="服务商品名称" align="center"/>
                <el-table-column prop="skuNo" label="货号" align="center"/>
                <el-table-column prop="spec" label="规格/尺寸" align="center"/>
                <el-table-column prop="specNo" label="编码" align="center"/>
                <el-table-column prop="price" label="价格" align="center"/>
                <el-table-column prop="createTime" label="购买时间" align="center"/>
              </el-table>
              <div class="pagination">
                <el-pagination
                  background
                  @current-change="cpPage"
                  :pager-count="7"
                  layout="total, prev, pager, next"
                  :total="pagination.count"
                  :page-count="pagination.pageCount"
                  :current-page="pagination.pageNumber"
                ></el-pagination>
              </div>
            </div>
          </div>
          <div v-if="type==2" class="mb-tab">
            <div class="xq-nr">
              <div class="xq-titel">
                <span style="width: 20%;">套餐名称</span>
                <span style="width: 20%;">项目名称</span>
                <span style="width: 20%;">总次数</span>
                <span style="width: 20%">使用次数</span>
                <span style="width: 20%">剩余次数</span>
              </div>
              <div class="xq-items">
                <div class="xq-item" v-if="miList.length>0" v-for="(item,index) in miList">
                  <div class="xqi-nr">
                    <div class="xqi-dv">
                      <span>{{item.tcName}}</span>
                    </div>
                    <div class="xqi-hb">
                    <span class="lspan1">
                    <div class="xqi-sp" v-for="it in item.smis">{{it.itemName}}</div>
                    </span>
                    </div>
                    <div class="xqi-hb">
                    <span class="lspan1">
                   <div class="xqi-sp" v-for="it in item.smis">{{it.total}}</div>
                    </span>
                    </div>
                    <div class="xqi-hb">
                    <span class="lspan1">
                 <div class="xqi-sp" v-for="it in item.smis">{{it.uses}}</div>
                    </span>
                    </div>
                    <div class="xqi-hb">
                    <span class="lspan1">
                    <div class="xqi-sp" v-for="it in item.smis">{{it.total-it.uses}}</div>
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="type==3" class="mb-tab">
            <el-table
              :data="yjnList"
              height="400px"
              :header-cell-style="{fontSize:'13px'}"
              :cell-style="{fontSize:'12px'}"
              style="width: 100%;">
              <el-table-column prop="itemName" :key="Math.random()" label="名称" align="center"/>
              <el-table-column prop="" label="总次数" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.total==-1">不限次数</span>
                  <span v-else>{{scope.row.total}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="miType" :key="Math.random()" label="属性" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.miType==4">月卡</span>
                  <span v-if="scope.row.miType==5">季卡</span>
                  <span v-if="scope.row.miType==6">年卡</span>
                </template>
              </el-table-column>
              <el-table-column prop="dueDate" :key="Math.random()" label="有效期" align="center">
                <template slot-scope="scope">
                  <span style="color: #ff4d51">{{scope.row.dueDate}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="uses" :key="Math.random()" label="已使用" align="center"/>
              <el-table-column prop="total" :key="Math.random()" label="总次数" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.total==-1">不限次数</span>
                  <span v-else>{{scope.row.total-scope.row.uses}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-if="type==4" class="mb-tab">
            <el-table
              :data="consumeList"
              height="400px"
              :header-cell-style="{fontSize:'13px'}"
              :cell-style="{fontSize:'12px'}"
              style="width: 100%;">
              <el-table-column prop="orderNo" :key="Math.random()" label="单号" show-overflow-tooltip align="center"/>
              <el-table-column prop="adsName" :key="Math.random()" label="客户" align="center">
                <template slot-scope="scope">
                  <div style="display: flex;flex-direction: column">
                    <span>{{scope.row.memberName}}</span>
                    <span v-if="scope.row.mcId">会员卡: {{scope.row.cardName}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="amount" :key="Math.random()" width="80" label="性别" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.orderSex==1">男</span>
                  <span v-if="scope.row.orderSex==2">女</span>
                </template>
              </el-table-column>
              <el-table-column prop="orderInfoList" :key="Math.random()" label="内容" width="300" align="center">
                <template slot-scope="scope">
                  <div style="display: flex;flex-direction: row" v-for="item in scope.row.orderInfoList">
                  <div style="text-align: left;display:flex;flex-direction: row;justify-items: center;align-items: center" >
                  <div style="margin-right: 6px;color: #ff4d51;">
                    <span class="sp-type" v-if="item.commodityType==0">
                    <span v-if="item.commoditySign==0">(项目)</span>
                    <span v-if="item.commoditySign==1||item.commoditySign==4">(套餐消耗)</span>
                    <span v-if="item.commoditySign==2">(会员卡消耗)</span>
                    <span v-if="item.commoditySign==3">(年卡消耗)</span>
                    <span v-if="item.commoditySign==5">(商城消耗)</span>
                  </span>
                  <span class="sp-type" v-if="item.commodityType==1">(卖品)</span>
                  <span class="sp-type" v-if="item.commodityType==2">(套餐)</span>
                  <span class="sp-type" v-if="item.commodityType==3">(开卡)</span>
                  <span class="sp-type" v-if="item.commodityType==4">
                    <el-popover placement="right" width="200" trigger="click" @show="toCzItem(item.id)">
                      <div style="display:flex;flex-direction: column">
                        <span class="ob-sp" v-if="scope.row.turnover">充值金额: <span class="ob-val">{{scope.row.turnover}}</span></span>
                        <span class="ob-sp" >赠送金额: <span class="ob-val">{{scope.row.giveAmount==null?'0':scope.row.giveAmount}}</span></span>
                        <span class="ob-sp" v-if="czitem&&czitem.memberId">扣欠款: <span class="ob-val">{{czitem.amount}}</span></span>
                      </div>
                      <el-button type="text" slot="reference">(充值)</el-button>
                    </el-popover>
                  </span>
                  <span class="sp-type" v-if="item.commodityType==5">(品项)</span>
                  <span class="sp-type" v-if="item.commodityType==6">(开支)</span>
                  <span class="sp-type" v-if="item.commodityType==7">(提现)</span>
                  <span class="sp-type" v-if="item.commodityType==8">(借支)</span>
                  <span class="sp-type" v-if="item.commodityType==9">(收入)</span>
                  <span class="sp-type" v-if="item.commodityType==10">(成品)</span>
                  </div>
                    <div >
                    <span >{{item.commodityName}}</span>
                    </div>
                    <div style="text-align: left;margin-left: 16px">
                      ¥ {{item.price}}
                    </div>
                  </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="totalPrice" :key="Math.random()" label="结算金额即方式" width="160" align="center">
                <template slot-scope="scope">
                  <div>
                  <span class="lsiv-sp">¥ {{scope.row.totalPrice}}
                  <span class="lsiv-sp" v-if="scope.row.payType==0">(会员卡)</span>
                  <span class="lsiv-sp" v-if="scope.row.payType==1">(赠送金)</span>
                  <span class="lsiv-sp" v-if="scope.row.payType==2">(现金)</span>
                  <span class="lsiv-sp" v-if="scope.row.payType==3">(微信)</span>
                  <span class="lsiv-sp" v-if="scope.row.payType==4">(支付宝)</span>
                  <span class="lsiv-sp" v-if="scope.row.payType==5">(美团)</span>
                  <span class="lsiv-sp" v-if="scope.row.payType==6">(抖音)</span>
                  <span class="lsiv-sp" v-if="scope.row.payType==7" style="cursor: pointer">
                     <el-popover placement="right" width="200" trigger="click" @show="toOrderBlend(scope.row.id)">
                      <div style="display:flex;flex-direction: column">
                        <span class="ob-sp" v-if="orderBlend.card&&orderBlend.card>0">会员卡: <span class="ob-val">{{orderBlend.card}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.give&&orderBlend.give>0">赠送金: <span class="ob-val">{{orderBlend.give}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.cash&&orderBlend.cash>0">现  金: <span class="ob-val">{{orderBlend.cash}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.wx&&orderBlend.wx>0">微  信: <span class="ob-val">{{orderBlend.wx}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.ali&&orderBlend.ali>0">支付宝: <span class="ob-val">{{orderBlend.ali}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.mt&&orderBlend.mt>0">美  团: <span class="ob-val">{{orderBlend.mt}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.dy&&orderBlend.dy>0">抖  音: <span class="ob-val">{{orderBlend.dy}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.arrears&&orderBlend.arrears>0">欠  款: <span class="ob-val">{{orderBlend.arrears}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.sj&&orderBlend.sj>0">商  家: <span class="ob-val">{{orderBlend.sj}}</span></span>
                      </div>
                      <el-button type="text" slot="reference">(混合支付)</el-button>
                    </el-popover>
                  </span>
                     <span class="lsiv-sp" v-if="scope.row.payType==8">(欠款)</span>
                  </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" :key="Math.random()" label="时间" align="center"/>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="consumePage"
                :pager-count="7"
                layout="total, prev, pager, next"
                :total="consumePagination.count"
                :page-count="consumePagination.pageCount"
                :current-page="consumePagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
          <div v-if="type==5" class="mb-tab">
            <div class="xq-nr">
              <div class="xq-titel">
                <span style="width: 20%;">商品名称</span>
                <span style="width: 20%;">项目名称</span>
                <span style="width: 20%;">总次数</span>
                <span style="width: 20%">使用次数</span>
                <span style="width: 20%">剩余次数</span>
              </div>
              <div class="xq-items">
                <div class="xq-item" v-if="shopItems.length>0" v-for="(item,index) in shopItems">
                  <div class="xqi-nr">
                    <div class="xqi-dv">
                      <span>{{item.name}}</span>
                    </div>
                    <div class="xqi-hb">
                    <span class="lspan1">
                    <div class="xqi-sp" v-for="it in item.memberItems">{{it.itemName}}</div>
                    </span>
                    </div>
                    <div class="xqi-hb">
                    <span class="lspan1">
                   <div class="xqi-sp" v-for="it in item.memberItems">{{it.total}}</div>
                    </span>
                    </div>
                    <div class="xqi-hb">
                    <span class="lspan1">
                 <div class="xqi-sp" v-for="it in item.memberItems">{{it.uses}}</div>
                    </span>
                    </div>
                    <div class="xqi-hb">
                    <span class="lspan1">
                    <div class="xqi-sp" v-for="it in item.memberItems">{{it.total-it.uses}}</div>
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="type==6" class="mb-tab">
            <div>
              <el-table
                :data="imList"
                :header-cell-style="{fontSize:'13px'}"
                :cell-style="{fontSize:'12px'}"
                style="width: 100%;">
                <el-table-column prop="memberName" label="头像" align="center">
                  <template slot-scope="scope">
                    <img v-if="!scope.row.headPortrait" src="../../assets/images/tx1.jpg"
                         style="width: 45px; height: 45px; border-radius: 6px;border: #E6E6E6 solid 1px">
                    <img v-if="scope.row.headPortrait" :src="scope.row.headPortrait"
                         style="width: 45px; height: 45px; border-radius: 6px;border: #E6E6E6 solid 1px">
                  </template>
                </el-table-column>
                <el-table-column prop="memberName" label="名称" align="center"/>
                <el-table-column prop="phone" label="手机号" align="center"/>
                <el-table-column prop="sex" label="性别" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.sex == 0"> 未知  </span>
                    <span v-if="scope.row.sex == 1"> 男  </span>
                    <span v-if="scope.row.sex == 2"> 女 </span>
                  </template>
                </el-table-column>
                <el-table-column prop="createTime" label="注册时间" align="center"/>
              </el-table>
              <div class="pagination">
                <el-pagination
                  background
                  @current-change="invitePage"
                  :pager-count="7"
                  layout="total, prev, pager, next"
                  :total="imPagination.count"
                  :page-count="imPagination.pageCount"
                  :current-page="imPagination.pageNumber"
                ></el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--表单组件-->
    <el-dialog append-to-body :close-on-click-modal="false" :before-close="closeEvent" :visible.sync="edit"
               title="编辑用户资料" width="630px">
      <div class="save-member">
        <el-form ref="mb" label-width="88px" :model="form" :rules="rules" size="mini">
          <span class="qian-bt">基本信息</span>
          <el-row style="margin-left: 10px">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-form-item label="姓名:" prop="memberName">
                  <el-input v-model="form.memberName" placeholder="请输入姓名" style="width: 180px"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <el-form-item label="性别:" prop="sex">
                  <el-radio-group v-model="form.sex" style="width: 180px">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-left: 10px">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-form-item label="手机号:" prop="phone">
                  <el-input v-model="form.phone" maxlength="11" show-word-limit style="width: 180px"
                            placeholder="请输入手机号"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <el-form-item label="生日:" prop="birthdayDate">
                  <el-date-picker
                    v-model="form.birthdayDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                    style="width: 180px"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <span class="qian-bt">高级设置</span>
          <el-form-item label="客户来源:" prop="mbSource" style="margin-left: 10px">
            <el-radio-group v-model="form.mbSource" style="width: 500px">
              <el-radio style="width: 68px;margin-bottom: 6px" :label="0">自来</el-radio>
              <el-radio style="width: 68px" :label="1">抖音</el-radio>
              <el-radio style="width: 68px" :label="2">美团</el-radio>
              <el-radio style="width: 68px;margin-bottom: 6px" :label="3">小红书</el-radio>
              <el-radio style="width: 68px;" :label="5">私域商城</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="原推荐人:" style="margin-left: 10px" v-if="tjmbItem.id">
            <el-input v-model="tjmbItem.memberName" disabled style="width: 180px">
            </el-input>
          </el-form-item>
          <el-row style="margin-left: 10px">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-form-item label="推荐人:" prop="superiorId">
                  <el-input v-model="tjmbName" placeholder="姓名/手机号" style="width: 180px">
                    <el-button slot="append" icon="el-icon-search" @click="toTjmbList"></el-button>
                  </el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <el-form-item label="推荐人:" prop="superiorId" v-if="tjmb.id">
                  <el-input v-model="tjmb.memberName" disabled style="width: 165px">
                  </el-input>
                  <span style="color: #ff4d51;cursor: pointer;margin-left: 5px" @click="delYq">删除</span>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-form-item label="注册时间:" prop="createDate" style="margin-left: 10px">
            <el-date-picker
              v-model="form.createDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              style="width: 180px"
            ></el-date-picker>
          </el-form-item>
          <span class="qian-bt">客户分配</span>
          <el-form-item label="第一负责人:" prop="superiorId" style="margin-left: 10px">
            <el-select v-model="form.oneId" placeholder="请选择">
              <el-option
                v-for="item in fEmps"
                :key="item.id"
                :label="item.nickName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第二负责人:" prop="superiorId" style="margin-left: 10px">
            <el-select v-model="form.twoId" placeholder="请选择">
              <el-option
                v-for="item in sEmps"
                :key="item.id"
                :label="item.nickName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第三负责人:" prop="superiorId" style="margin-left: 10px">
            <el-select v-model="form.threeId" placeholder="请选择">
              <el-option
                v-for="item in tEmps"
                :key="item.id"
                :label="item.nickName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary" @click="saveMember">确 定</el-button>
         <el-button size="small" type="" @click="closeEvent">取 消</el-button>
        </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="邀请人" :visible.sync="yqVisible" width="300px"
               :before-close="yqEvent">
      <div class="yqlist">
        <div style="margin-bottom: 8px">
          <el-input v-model="tjmbName" placeholder="姓名/手机号" size="small">
            <el-button slot="append" icon="el-icon-search" @click="toTjmbList"></el-button>
          </el-input>
        </div>
        <el-table
          :data="tjmbs"
          size="mini"
          height="300"
          style="width: 100%">
          <el-table-column prop="memberName" label="姓名" align="center"/>
          <el-table-column prop="phone" label="手机号" align="center"/>
          <el-table-column prop="phone" label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="small" type="primary" @click="setTjmb(scope.row)">选择
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="编辑密码" :visible.sync="mmVisible" width="300px"
               :before-close="mmEvent">
      <div>
        <el-input v-model="mbPwd" size="small" placeholder="请输入密码" style="width: 460px"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary" @click="updatePwd('PWD')">确 定</el-button>
         <el-button size="small" type="" @click="mmEvent">取 消</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
  import {getToken} from "../../utils/auth";

  export default {
    components: {},
    data() {
      return {
        type: 0,
        loading: false,

        edit: false,
        form: {},
        yqVisible: false,
        tjmbName: '',
        tjmbs: [],
        tjmb: {},
        tjmbItem: {},
        fEmps: [],
        sEmps: [],
        tEmps: [],

        mmVisible: false,
        mbPwd: '',

        xfDate:'',
        swRes: {},
        dept: {},
        memberId: '',
        member: {},
        list: [],
        cpList: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        tcIndex: 0,
        miList: [],
        yjnList: [],
        consumeList: [],
        consumePagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
        shopItems: [],
        imList: [],
        imPagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
        orderBlend:{},
        czitem:{},
        rules: {
          memberName: [
            {required: true, message: '请输入名称', trigger: 'blur'}
          ],
          phone: [
            {required: true, message: '请输入手机号', trigger: 'blur'}
          ],
          sex: [
            {required: true, message: '请选择性别', trigger: 'blur'}
          ],
          birthdayDate: [
            {required: true, message: '请输入生日', trigger: 'blur'}
          ],
          createDate: [
            {required: true, message: '注册时间', trigger: 'blur'}
          ],
          mbSource: [
            {required: true, message: '请选择来源', trigger: 'blur'}
          ]
        },
      }
    },
    mounted() {
      this.memberId = this.$route.query.memberId
      this.getMemberDetail()
      this.getSwStatistics()
      this.getEmpList()
    },
    watch: {
      type(val) {
        if (val == 0) {
          this.getMemberCards()
        } else if (val == 1) {
          this.getBuySkuCpList()
        } else if (val == 2) {
          this.getMemberItems()
        } else if (val == 3) {
          this.getYjnMemberItems()
        } else if (val == 4) {
          this.getConsumeRecordList()
        } else if (val == 5) {
          this.getShopItems()
        } else if (val == 6) {
          this.getInviteMemberForPage()
        }
      }
    },

    methods: {
      async getSwStatistics() {
        let data = {
          memberId: this.memberId,
          token: getToken(),
        }
        let res = await this.$get("/sw/getSwStatistics", data)
        this.swRes = res.swRes
      },

      toDetails() {
      },
      toPwd() {
        this.mmVisible = true
      },
      mmEvent() {
        this.mmVisible = false
      },
      toEditMember() {
        this.form = {
          id: this.memberId,
          memberName: this.member.memberName,
          phone: this.member.jmPhone,
          sex: this.member.sex,
          mbSource: this.member.mbSource,
          birthdayDate: new Date(this.member.birthday),
          createDate: new Date(this.member.createTime),
          oneId: this.member.oneId,
          twoId: this.member.twoId,
          threeId: this.member.threeId,
          token: getToken(),
        }
        this.edit = true
      },

      closeEvent() {
        this.edit = false
      },

      setMbtc(index) {
        this.tcIndex = index
      },


      async getMemberDetail() {
        this.loading = true
        let res = await this.$get("/sw/getMemberDetail", {memberId: this.memberId, token: getToken()})
        this.loading = false
        this.member = res.member
        this.xfDate = res.member.xfDate
        if (res.tjmb && res.tjmb.id) {
          this.tjmbItem = res.tjmb
        } else {
          this.tjmbItem = {}
        }
      },


      //获取混合支付的详细明细
      async toOrderBlend(orderId) {
        if (this.orderBlend.orderId&&this.orderBlend.orderId==orderId){
          return ;
        }else {
          this.orderBlend={}
        }
        let res = await this.$get("/sw/getOrderBlend", {orderId: orderId})
        if (res.orderBlend) {
          this.orderBlend = res.orderBlend
        }
      },

      //查看充值是否存在还款
      async toCzItem(orderId) {
        if (this.czitem.hkOrderId&&this.czitem.hkOrderId==orderId){
          return ;
        }else {
          this.czitem={}
        }
        let res = await this.$get("/sw/getCzItem", {orderId: orderId})
        if (res.czitem) {
          this.czitem = res.czitem
        }
      },

      toEditTj() {
        this.yqVisible = true
      },

      async toTjmbList() {
        if (!this.tjmbName) {
          this.$message.error("请输入查询条件");
          return;
        }
        let data = {
          tjmbName: this.tjmbName,
          token: getToken(),
        }
        let res = await this.$get("/sw/getSwTjmbs", data);
        this.tjmbs = res.list
        this.yqVisible = true
      },
      //获取员工列表
      async getEmpList() {
        let res = await this.$get("/sw/getSwEmpList", {token: getToken()});
        this.fEmps = res.fEmps;
        this.sEmps = res.sEmps;
        this.tEmps = res.tEmps;
      },

      async setTjmb(item) {
        if (!item && !item.id) {
        }
        let data = {
          id: this.member.id,
          superiorId: item.id
        }
        let res = await this.$post("/sw/updateSuperiorId", data)
        if (res.code == 200) {
          this.$message.success(res.message)
          this.yqEvent()
          this.getMemberDetail()
        } else {
          this.$message.error(res.message)
        }
      },

      yqEvent() {
        this.yqVisible = false
      },

      delYq() {
        this.tjmbs = []
        this.tjmbName = ''
        this.tjmb = ''
      },

      async saveMember() {
        if (this.tjmb && this.tjmb.id) {
          this.form.superiorId = this.tjmb.id
        }
        let res = await this.$post("/sw/updateMember", this.form)
        if (res.code == 200) {
          this.$message.success(res.message)
          this.closeEvent()
          this.getMemberDetail()
        } else {
          this.$message.error(res.message)
        }
      },
      updateAddWx() {
        this.updatePwd('ADDWX')
      },
      async updatePwd(type) {
        let data = {
          id: this.memberId,
        }
        if (type == 'PWD') {
          data.mbPwd = this.mbPwd
        }
        if (type == 'ADDWX') {
          data.addWx = this.member.addWx
        }
        let res = await this.$post("/sw/updatePwd", data)
        if (res.code == 200) {
          this.$message.success(res.message)
          this.mmEvent()
        } else {
          this.$message.error(res.message)
        }
      },

      async updateMemberRemarks(remarks) {
        let data = {
          id: this.memberId,
          remarks: remarks
        }
        let res = await this.$post("/sw/updateMemberRemarks", data)
      },

      async getMemberCards(page) {
        let currentPage = page || 1;
        let data = {
          memberId: this.memberId,
          // deptId: this.dept.deptId,
          currentPage
        }
        let res = await this.$get("/glc/getGlcMemberCardForPage", data)
        let list = res.dataSet.list;
        this.list = list;
        this.pagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pageCount: res.dataSet.pages
        }
      },

      cardPage(page) {
        this.getMemberCards(page)
      },


      async getBuySkuCpList(page) {
        let currentPage = page || 1;
        let data = {
          memberId: this.memberId,
          currentPage
        }
        let res = await this.$get("/glc/getBuySkuCpForPage", data)
        let list = res.dataSet.list;
        this.cpList = list;
        this.pagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pageCount: res.dataSet.pages
        }
      },

      cpPage(page) {
        this.getBuySkuCpList(page)
      },


      async getMemberItems() {
        let data = {
          memberId: this.memberId,
        }
        let res = await this.$get("/glc/getGlcMemberItems", data)
        this.miList = res.miList;
      },


      async getYjnMemberItems() {
        let data = {
          memberId: this.memberId
        }
        let res = await this.$get("/glc/getYjnMemberItems", data)
        this.yjnList = res.list;
      },

      async getShopItems() {
        let data = {
          memberId: this.memberId
        }
        let res = await this.$get("/sw/getSwShopItems", data)
        this.shopItems = res.names;
      },
      //消费记录
      consumePage(page) {
        this.getConsumeRecordList(page)
      },
      async getConsumeRecordList(page) {
        let currentPage = page || 1;
        let data = {
          memberId: this.memberId,
          currentPage
        }
        let res = await this.$get("/glc/getConsumeRecordForPage", data)
        let list = res.dataSet.list;
        this.consumeList = list;
        this.consumePagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pageCount: res.dataSet.pages
        }
      },

      //邀请的用户
      invitePage(page) {
        this.getInviteMemberForPage(page)
      },
      async getInviteMemberForPage(page) {
        let currentPage = page || 1;
        let data = {
          superiorId: this.memberId,
          currentPage
        }
        let res = await this.$get("/glc/getInviteMemberForPage", data)
        let list = res.dataSet.list;
        this.imList = list;
        this.imPagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pageCount: res.dataSet.pages
        }
      },
      goBack() {
        this.$router.go(-1)
      }
    }
  }
</script>

<style scoped>

  .mb-detail {
    width: 100%;
    height: 100%;
    font-size: 12px !important;
    display: flex;
    flex-direction: row;
    background-color: #F1F5FB;
    padding: 8px;
  }

  .mb-member {
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 15px;
    margin-right: 8px;
  }

  .mbinfo-top {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    background-color: #656A81;
    padding: 18px;
  }

  .img {
    width: 68px;
    height: 68px;
    border-radius: 40px;
  }

  .mbinfo-top .mb-item {
    display: flex;
    flex-direction: column;
    margin-left: 13px;
    width: 200px;
  }

  .mbinfo-top .mb-item span {
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    color: #ffffff;
  }

  .mbinfo-zhong {
    display: flex;
    flex-direction: column;
  }

  .mz-sp {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }


  .mb-title {
    font-size: 16px;
  }

  .sku-con span {
    font-size: 10px;
  }

  .mb-record {
    width: 75%;
    display: flex;
    flex-direction: column;
    margin-right: 8px;
  }

  .mbr-top img {
    height: 247px;
  }

  .mbr-bottom {
    background-color: #ffffff;
    margin-top: 8px;
  }

  .mb-tcs {
    display: flex;
    flex-direction: row;
    margin-top: -15px;
  }

  .xq-nr {
    width: 98%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    margin-top: 20px;
  }

  .xq-titel {
    display: flex;
    flex-direction: row;
    border-bottom: #dfe6ec solid 1px;
    font-size: 13px;
    color: #909399;
  }

  .xq-titel-sp {
    text-align: center;
    line-height: 32px;
    width: 16.5%;
  }

  .xq-titel span {
    text-align: center;
    line-height: 32px;
  }

  .xq-items {
    width: 100%;
    font-size: 12px;
    color: #606266;
  }

  .xq-items1 {
    width: 100%;
    font-size: 12px;
    color: #606266;
  }

  .xq-item {
    padding-top: 3px;
    padding-bottom: 3px;
    border-bottom: #dfe6ec solid 1px;
  }

  .xq-item1 {
    padding-top: 3px;
    padding-bottom: 3px;
    border-bottom: #dfe6ec solid 1px;
  }

  .xqi-hb {
    width: 20%;
    text-align: center;
  }

  .xqi-hb1 {
    width: 16.5%;
    text-align: center;
  }

  .xqi-dv {
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    width: 20%;
  }


  .xqi-dv span {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 3px;
  }

  .xqi-dv1 span {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 3px;
  }

  .xqi-nr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
  }

  .xqi-nr1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
  }

  .sp-type {
    display: inline-block;
    float: left;
    padding-left: 10px;
    margin-right: 8px;
    color: #ff4d51;
  }

  .xqi-sp {
    display: block;
    line-height: 32px;
    border-bottom: #EEEEEE solid 1px;
  }


  .xqi-sp .lspan2 {
    display: inline-block;
    width: 39%;
  }

  .mb-sex {
    height: 18px;
    line-height: 18px;
    padding: 0px 2px;
    color: #ffffff;
    background-color: #F47115;
    border-radius: 3px;
  }

  .el-dialog__body {
    border-top: silver solid 1px;
  }

  .save-member {
  }

  .qian-bt {
    border-left: #1256FA solid 4px;
    padding-left: 3px;
    font-size: 15px;
    display: block;
    margin-bottom: 16px;
  }

  .mb-tongji {
    background-color: #ffffff;
    padding: 15px;
  }

  .tj-items {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .tj-item {
    width: 25%;
    padding: 10px;
  }

  .tj-nnn {
    height: 70px;
    padding: 20px;
    font-size: 15px;
  }

  .tj-value {
    margin-left: 25%;
    margin-top: 26px;
  }

  .mb-tab {
    background-color: #ffffff;
    height: 500px;
  }
  .ob-sp {
    height: 23px;
    line-height: 23px;
    text-align: right;
  }

  .ob-val {
    display: inline-block;
    width: 120px;
    text-align: left;
    padding-left: 3px;
  }
</style>
